<template>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <v-card-title >
          5. Порядок обучения служащих мерам ПБ на рабочих местах
        </v-card-title>
        <v-card-text class="title font-weight-light d-flex justify-center">
          Лица допускаются к работе только после прохождения обучения мерам ПБ.<br>Руководитель организации вправе назначить лиц, которые по занимаемой должности или по характеру выполняемых работ являются ответственными за обеспечение ПБ на объекты защиты.
        </v-card-text>
        <v-card-title class="mb-0 pb-0">
          Обучение лиц мерам ПБ с помощью:
        </v-card-title>
      </v-col>
      <v-col cols="12">
          <v-tabs
            color="red"
            v-model="tab"
          >
            <v-tab width="100%" class="mr-10">Пожарно-технический минимум</v-tab>
            <v-tab>Противопожарный инструктаж</v-tab>
          </v-tabs>
          <v-tabs-items
          v-model="tab"
          >
            <v-tab-item
            vertical
            >
              <v-card flat>
                <v-card-text class="title font-weight-light pt-5">
                <span class="font-weight-bold">Цель</span>: обучение руководителей, специалистов и работников организаций, ответственных за ПБ в области противопожарного режима, пожарной опасности и технологического процесса, и производства организации, а также действием при возникновении и по предупреждению пожара.
                </v-card-text>
                <v-card-title >
                  Периодичность обучения:
                </v-card-title>
                <v-card flat>
                  <v-row class="align-center">
                    <v-col cols="6">
                      <div class="d-flex">
                        <v-img
                          lazy-src="img/material/cal-31.svg"
                          max-height="100%"
                          max-width="20%"
                          contain
                          src="img/material/cal-31.svg"
                        >
                        </v-img>
                        <v-card-text class="title font-weight-light">
                          В течение <span class="red white--text pa-0.5"><strong>1 месяца</strong></span> после приема на работу и далее <span class="red white--text"><strong> не реже 1 раза в 3 года</strong></span>
                          <br>- для организаций, не связанных с взрывопожароопасным производством
                        </v-card-text>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex">
                        <v-img
                          lazy-src="img/material/cal-365.svg"
                          max-height="100%"
                          max-width="20%"
                          contain
                          src="img/material/cal-365.svg"
                        >
                        </v-img>
                        <v-card-text class="title font-weight-light">
                          <span class="red white--text pa-0.5"><strong>1 раз  в год</strong></span> - для организаций, связанных с взрывопожароопасным производством.
                        </v-card-text>
                      </div>
                    </v-col>
                  </v-row>
                  <v-card-title >
                    Периодичность обучения:
                  </v-card-title>
                  <v-row>
                    <v-col cols="4">
                      <v-dialog
                        v-model="dialog1"
                        width="80%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            Обучение с отрывом от производства
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Обучение с отрывом от производства
                          </v-card-title>

                          <v-card-title>
                            Проводится в:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            образовательных учреждениях пожарно-технического профиля;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            учебных центрах Федеральной противопожарной службы МЧС РФ;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            учебно-методических центрах по гражданской оборон и ЧС субъектов РФ;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            территориальных организациях Государственной противопожарной службы МЧС РФ;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            организациях, оказывающих услуги по обучению населения мерам ПБ;
                          </v-card-text>

                          <v-card-title>
                            Проходят:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            руководители и главные специалисты;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работники, ответственные за ПБ и проведение противопожарного инструктажа;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            руководители первичных организаций добровольной пожарной охраны;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            руководители загородных оздоровительных учреждений для детей и подростков;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работники, выполняющие огневые работы (газоэлектросварочные);
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            водители пожарных автомобилей и мотористы мотопомп детских оздоровительных учреждений;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            водители пожарных автомобилей и мотористы мотопомп детских оздоровительных учреждений;
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog1 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4">
                      <v-dialog
                        v-model="dialog2"
                        width="80%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            Обучение без отрыва от производства
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Обучение в организации проводится руководителем или лицом, назначенным ответственным за ПБ и имеющим<br> соответствующую подготовку
                          </v-card-title>

                          <v-card-title>
                            Проходят:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            руководители подразделений, руководители и главные специалисты подразделений взрывоопасных производств;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работники, ответственные за ПБ в подразделениях;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            педагогические работники дошкольных образовательных учреждений;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работники, осуществляющие круглосуточную охрану организаций;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            граждане, участвующие в деятельности подразделений пожарной охраны по предупреждению и (или) тушению пожара на добровольной основе;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работники, привлекаемые к выполнению взрывоопасных работ.
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog2 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4">
                      <v-dialog
                        v-model="dialog3"
                        width="80%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            Не проходят обучение
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Не проходят обучение:
                          </v-card-title>

                          <v-card-title>
                           Работники, которые могут не проходить обучение ПТМ в течение года после поступления на работу:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            имеющие квалификацию инженера (техника) ПБ;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работающие в федеральном органе исполнительной власти, уполномоченном на решение задача области ПБ (и его структурных подразделениях);
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            преподающие дисциплину «Пожарная безопасность» в образовательных учреждениях;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            имеющие стаж непрерывной работы в области ПБ не менее 5 лет.
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog3 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text class="title font-weight-light pt-5">
                <span class="font-weight-bold">Цель</span>: доведение до работников требований ПБ, изучение пожарной опасности технологических процессов производств и оборудования, средств противопожарной защиты, действий в случае возникновения пожара.
                </v-card-text>
                <v-card-title >
                  Противопожарный инструктаж проводится:
                </v-card-title>
                <v-card flat>
                  <v-row class="align-center">
                    <v-col cols="6">
                      <div class="d-flex flex-column">
                        <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            руководителем организации;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            по спецальным программам обучения мерам ПБ;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            в порядке, определяемом руководетелем;
                        </v-card-text>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex flex-column">
                        <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            с учетом специфики деятельности организации;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            с записью в журнале учета проведения инструктажей по ПБ с обязательной подписью инструктируемого и инструктирующего.
                        </v-card-text>
                      </div>
                    </v-col>
                  </v-row>
                  <v-card-title >
                    Инструктаж по характеру и времени проведения подразделяется на:
                  </v-card-title>
                  <v-row>
                    <v-col cols="2">
                      <v-dialog
                        v-model="dialog4"
                        width="50%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                          <v-icon class="mr-3" size="40">mdi-account-hard-hat</v-icon>
                            Вводный
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Вводный инструктаж проводится с:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            с новыми работниками независимо от образования и стажа;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            сезонными работниками;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            командированными в организацию работниками;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            с прибывшими на производственное обучение или практику;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            иными работниками по решению руководителя.
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog4 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="2">
                      <v-dialog
                        v-model="dialog5"
                        width="50%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                          <v-icon class="mr-3" size="40">mdi-account-tie</v-icon>
                            Первичный
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Первичный инструктаж проводится с:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            новыми работникам;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            переведенными из одного подразделения в другое;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            работниками, выполняющими новую для них работу;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            командированными в организацию работниками;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            сезонными работниками;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            выполняющими строительно-монтажные и инные работы на территории организации.
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog5 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="2">
                      <v-dialog
                        v-model="dialog6"
                        width="50%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                          <v-icon class="mr-3" size="40">mdi-account-reactivate</v-icon>
                            Повторный
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Повторный инструктаж проводится:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            со всеми работникамине реже 1 раза в год;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            с работниками пожароопасного производства не реже 1 раза в 6 месяцев;
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog6 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4">
                      <v-dialog
                        v-model="dialog7"
                        width="50%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="pa-5 mb-4"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                          <v-icon class="mr-3" size="40">mdi-badge-account-alert</v-icon>
                            Внеплановый
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Внеплановый инструктаж проводится при:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            изменении или введении новых норм, правил, инструкций по ПБ;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            изменении технологического процесса производства, замене или модернизации оборудования, инструментов, сырья;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            нарушении работниками требований ПБ;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            по требованию органов государственного пожарного надзора;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            перерывах в работе более 30 календарных дней;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            поступлении материалов об авариях, пожарах на аналогичных производствах;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            недостаточных знаниях требований ПБ у работников.
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog7 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="2">
                      <v-dialog
                        v-model="dialog8"
                        width="50%"
                        class="pa-16"
                      >
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn
                            class="pa-5 mb-2"
                            width="100%"
                            height="100"
                            color="red"
                            dark
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                          <v-icon class="mr-3" size="40">mdi-target-account</v-icon>
                            Целевой
                          </v-btn >
                        </template>

                        <v-card class="noselect">
                          <v-card-title class="headline" dark>
                            Целевой инструктаж проводится при:
                          </v-card-title>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            выполнении разовых работ, связанных с повышенной пожарной опасностью (сварочные и другие огневые работы);
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            ликвидации последсвий аварий, стихийных бедствий и катастроф;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            производстве работ, на которые оформляются наряд-допуск;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            проведение экскурсий в организации;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            организации массовых мероприятий с обучающимися;
                          </v-card-text>

                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            организации мероприятий с числом участников более 50 человек.
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="red"
                              text
                              @click="dialog8 = false"
                            >
                              Закрыть
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-col>
      <v-col>
        <Hint/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script >
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null,
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    dialog5: null,
    dialog6: null,
    dialog7: null,
    dialog8: null
  }),
  components: {
    Hint
  }
}
</script>
